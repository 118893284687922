/**
 * CIconRowInsert
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconRowInsert extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={['anticon', 'c-icon-row-insert', this.props.className].join(
                    ' ',
                )}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M127.744 212.778667a42.410667 42.410667 0 0 1 0-84.778667h768.512a42.410667 42.410667 0 0 1 0 84.778667H127.744z m0 682.666666a42.410667 42.410667 0 0 1 0-84.778666h768.512a42.410667 42.410667 0 1 1 0 84.778666H127.744zM128 469.333333h213.333333a42.666667 42.666667 0 0 1 0 85.333334H128a42.666667 42.666667 0 0 1 0-85.333334z m652.501333 66.346667a42.666667 42.666667 0 0 1 0-60.330667l130.133334-114.389333a42.666667 42.666667 0 0 1 70.826666 32v217.258667a42.666667 42.666667 0 0 1-69.674666 33.024l-131.285334-107.52z"></path>
                </svg>
            </span>
        );
    }
}
