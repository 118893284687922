/**
 * CIconRowAddNew
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconRowAddNew extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={['anticon', 'c-icon-row-add-new', this.props.className].join(
                    ' ',
                )}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M68.5568 362.0864a46.08 46.08 0 0 1 46.08-46.08h114.2784V201.728a46.08 46.08 0 0 1 92.16 0v114.2784h114.2784c25.4464-0.1024 46.1824 20.4288 46.2848 45.8752 0.1024 25.4464-20.4288 46.1824-45.8752 46.2848H321.024v114.2784c0.1024 25.4464-20.4288 46.1824-45.8752 46.2848-25.4464 0.1024-46.1824-20.4288-46.2848-45.8752V408.1152H114.5856a46.08 46.08 0 0 1-46.08-46.08z m816.5376 103.2192h-317.0304a46.08 46.08 0 0 0 0 92.16h317.0304v211.968h-737.28v-114.2784a46.08 46.08 0 0 0-92.16 0v114.2784c0 50.8928 41.2672 92.16 92.16 92.16h737.28c50.8928 0 92.16-41.2672 92.16-92.16v-211.968c0-50.8928-41.2672-92.16-92.16-92.16z"></path>
                </svg>
            </span>
        );
    }
}
