/**
 * CIconRowClearAll
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconRowClearAll extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={['anticon', 'c-icon-row-clear-all', this.props.className].join(
                    ' ',
                )}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M182.930286 1023.853714a36.571429 36.571429 0 0 1-36.571429-36.571428v-658.285715a36.571429 36.571429 0 0 1 36.571429-36.571428 36.571429 36.571429 0 0 1 36.571428 36.571428v621.714286h585.142857v-621.714286a36.571429 36.571429 0 0 1 36.571429-36.571428 36.571429 36.571429 0 0 1 36.571429 36.571428v658.285715a36.571429 36.571429 0 0 1-36.571429 36.571428z m486.107428-193.609143L512 673.28l-156.964571 156.964571a36.571429 36.571429 0 0 1-25.892572 10.752 36.571429 36.571429 0 0 1-25.892571-10.752 36.571429 36.571429 0 0 1 0-51.785142l156.964571-156.964572L303.250286 464.457143a36.571429 36.571429 0 0 1 0-51.785143 36.571429 36.571429 0 0 1 51.785143 0L512 569.709714l157.037714-157.037714a36.571429 36.571429 0 0 1 51.785143 0 36.571429 36.571429 0 0 1 0 51.785143L563.785143 621.494857l157.037714 156.964572a36.571429 36.571429 0 0 1 0 51.785142 36.571429 36.571429 0 0 1-25.892571 10.752 36.571429 36.571429 0 0 1-25.892572-10.678857zM731.428571 219.355429H104.521143a34.304 34.304 0 0 1-31.378286-36.571429 34.230857 34.230857 0 0 1 31.378286-36.571429H292.571429v-146.285714h438.857142v146.285714h188.123429a34.230857 34.230857 0 0 1 31.378286 36.571429 34.304 34.304 0 0 1-31.378286 36.571429z m-365.714285-73.142858h292.571428v-73.142857H365.714286z"></path>
                </svg>
            </span>
        );
    }
}
