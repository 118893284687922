/**
 * CFormTable
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';
// import Schema from 'async-validator';
// import { validateMsgs } from './validateMsgs.js';
import { BFormTableContext } from '@components/fragments/formTable/bases/BFormTableContext';

export class CFormTableItem extends React.Component {
    static contextType = BFormTableContext;

    static propTypes = {
        // 当前单元格键名
        dataIndex: PropTypes.string,
        // 当前单元格行数
        rowIndex: PropTypes.number,
    };

    static defaultProps = {};

    constructor(props) {
        super(props);
        this.state = {};
        this.$refs = {
            children: null,
        };
    }

    render() {
        const { getItemValue, setItemValue, onChildFocus } = this.context;
        const { children, dataIndex, rowIndex } = this.props;
        const clonedChild = React.cloneElement(children, {
            // 注入 value
            value: getItemValue(rowIndex, dataIndex),
            // 注入 onChange
            onChange: (...args) => {
                if (children.props.onChange) {
                    children.props.onChange(...args);
                }
                setItemValue(rowIndex, dataIndex, ...args);
            },
            // 注入 onFocus（主列聚焦用）
            onFocus: (...args) => {
                if (children.props.onFocus) {
                    children.props.onFocus(...args);
                }
                onChildFocus(rowIndex, dataIndex, ...args);
            },
            // 注入 ref（主列聚焦用）
            ref: elm => {
                if (children.props.ref) {
                    children.props.ref(elm);
                }
                this.$refs.children = elm;
            },
        });
        return <>{clonedChild}</>;
    }

    $childFocus() {
        if (!this.$refs.children.focus) {
            console.warn("FormTable: This element can't be foucused!");
            return;
        }
        this.$refs.children.focus();
    }
}
