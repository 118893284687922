/**
 * CIconRowCopy
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconRowCopy extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={['anticon', 'c-icon-row-copy', this.props.className].join(' ')}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M896 851.2c6.4 6.4 19.2 12.8 25.6 12.8 12.8 0 25.6 0 38.4-6.4 12.8-6.4 25.6-12.8 32-19.2 6.4-6.4 19.2-19.2 19.2-32 6.4-12.8 6.4-25.6 6.4-38.4V102.4c0-25.6-12.8-51.2-32-70.4-12.8-19.2-32-32-64-32H262.4c-25.6 0-51.2 12.8-70.4 32-19.2 19.2-32 38.4-32 70.4 0 12.8 6.4 19.2 12.8 25.6 6.4 6.4 19.2 12.8 32 12.8s19.2-6.4 25.6-12.8c6.4-6.4 12.8-19.2 12.8-25.6 0-6.4 0-12.8 6.4-12.8 0-6.4 6.4-12.8 12.8-12.8h659.2c6.4 0 12.8 0 12.8 6.4 6.4 6.4 6.4 6.4 6.4 12.8v659.2c0 6.4 0 12.8-6.4 12.8-6.4 6.4-6.4 6.4-12.8 6.4-12.8 0-19.2 6.4-25.6 12.8-6.4 6.4-12.8 19.2-12.8 25.6 0 19.2 6.4 32 12.8 38.4zM761.6 1024c12.8 0 25.6 0 38.4-6.4 12.8-6.4 25.6-12.8 32-19.2 6.4-6.4 19.2-19.2 19.2-32 6.4-12.8 6.4-25.6 6.4-38.4V262.4c0-12.8 0-25.6-6.4-38.4s-6.4-25.6-19.2-32c-6.4-6.4-19.2-19.2-32-19.2-12.8-6.4-25.6-6.4-38.4-6.4H102.4c-32-6.4-51.2 6.4-70.4 25.6-19.2 19.2-32 44.8-32 70.4v659.2c0 25.6 12.8 51.2 32 70.4 19.2 19.2 38.4 32 70.4 32h659.2zM83.2 249.6c6.4-6.4 6.4-6.4 12.8-6.4h659.2c6.4 0 12.8 0 12.8 6.4 6.4 6.4 6.4 6.4 6.4 12.8v659.2c0 6.4 0 12.8-6.4 12.8-6.4 6.4-6.4 6.4-12.8 6.4H102.4c-6.4 0-12.8 0-12.8-6.4-6.4-6.4-6.4-6.4-6.4-12.8V262.4c-6.4-6.4 0-12.8 0-12.8z m345.6 556.8c-12.8 0-19.2-6.4-25.6-12.8s-12.8-12.8-12.8-25.6V633.6H256c-12.8 0-19.2-6.4-25.6-12.8-6.4-6.4-12.8-19.2-12.8-25.6 0-12.8 6.4-19.2 12.8-25.6 6.4-12.8 12.8-19.2 25.6-19.2h134.4V416c0-12.8 6.4-19.2 12.8-25.6s19.2-12.8 25.6-12.8c12.8 0 19.2 6.4 25.6 12.8s12.8 19.2 12.8 25.6v134.4h134.4c12.8 0 19.2 6.4 25.6 12.8 6.4 6.4 12.8 19.2 12.8 25.6 0 12.8-6.4 19.2-12.8 25.6 0 12.8-12.8 19.2-19.2 19.2H473.6V768c0 12.8-6.4 19.2-12.8 25.6s-19.2 12.8-32 12.8z"></path>
                </svg>
            </span>
        );
    }
}
