/**
 * Fetcher Product
 * @author Tevin
 */

import { Fetcher } from '@components/bases/Fetcher';
import MenuPathTree from '@fetchers/datas/MenuPathTree';

export class FProduct extends Fetcher {

    constructor() {
        super({
            urlPrefix: ['/api/product/', '/Home/Product/'],
            mockPath: MenuPathTree.projectName,
        });
    }

    // 获取商品列表（商品列表）
    getProductList(search, pagination, sorter) {
        const url = this.spellURL('getProductList', 'product_list');
        const send = {
            ...search,
            page: pagination.current,
            limit: pagination.pageSize,
            sortBy: sorter.field,
            sortOrder: sorter.order,
        };
        return this.post(url, send);
    }

    // 获取商品列表（筛选项）
    getProductListAsSelect(type) {
        const url = this.spellURL('getProductListFilter', 'product_list');
        const send = {
            page: 1,
            limit: 100,
            online: 1,
        };
        return this.post(url, send)
            .then(res => {
                if (!res) {
                    return null;
                }
                if (type && type === 'floorFee') {
                    return res.rows.map(item => ({
                        id: item.id,
                        name: item.name,
                        floor: item.floorState,
                    }));
                } else {
                    return res.rows.map(item => ({
                        id: item.id,
                        name: item.name,
                    }));
                }
            });
    }

    // 获取商品详细信息（商品列表）
    getProductInfo(product) {
        const url = this.spellURL('getProductInfo', 'edit_product_info');
        const send = {
            ...product,
        };
        return this.post(url, send);
    }

    // 保存商品详细信息（商品列表）
    saveProductInfo(product) {
        const url = this.spellURL('saveProductInfo', 'api_product');
        const send = {
            ...this.transKeyName('underline', this.trimEndsSpace(product)),
        };
        return this.post(url, send);
    }

    // 删除商品（商品列表）
    saveDeleteProduct(product) {
        const url = this.spellURL('saveDeleteProduct', 'api_product');
        const send = {
            ...this.transKeyName('underline', product),
        };
        return this.post(url, send);
    }

    // 获取商品区域价格（商品列表）
    getProductAreaPrice(search) {
        const url = this.spellURL('getProductAreaPrice', 'area_price');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存商品区域价格（商品列表）
    saveProductAreaPrice(price) {
        const url = this.spellURL('saveProductAreaPrice', 'area_price');
        const send = {
            ...this.transKeyName('underline', price),
        };
        return this.post(url, send);
    }

    // 获取商品门店价格（商品列表）
    getProductStorePrice(search) {
        const url = this.spellURL('getProductStorePrice', 'store_price');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 保存商品门店价格（商品列表）
    saveProductStorePrice(price) {
        const url = this.spellURL('saveProductStorePrice', 'store_price');
        const send = {
            ...this.transKeyName('underline', price),
        };
        return this.post(url, send);
    }

    // 获取商品可见性列表（商品列表）
    getProductVisibilityList(product) {
        const url = this.spellURL('getProductVisibilityList', 'member_type');
        const send = {
            ...this.transKeyName('underline', product),
        };
        return this.post(url, send);
    }

    // 保存商品会员类型可见性设置（商品列表）
    saveProductVisibility(visibility) {
        const url = this.spellURL('saveProductVisibility', 'member_type');
        const send = {
            ...this.transKeyName('underline', visibility),
        };
        return this.post(url, send);
    }

    // 保存指定会员类型开发，商品可见性总阀（商品列表）
    saveProductVisibilityValve(visibility) {
        const url = this.spellURL('saveProductVisibilityValve', 'api_product');
        const send = {
            ...this.transKeyName('underline', visibility),
        };
        return this.post(url, send);
    }

    // 获取会员可见的商品列表（添加订单）
    getProductByUser(search) {
        const url = this.spellURL('getProductByUser', '../Order/add_product');
        const send = {
            ...this.transKeyName('underline', search),
        };
        return this.post(url, send);
    }

    // 获取商品类型（商品列表）
    getProductType() {
        const url = this.spellURL('getProductType', '../../lpg/admin/Product/getProductType');
        return this.post(url);
    }
}

export const $fetchProduct = new FProduct();
