/**
 * CIconRowRemove
 * @author Tevin
 */

import React from 'react';
import PropTypes from 'prop-types';

export class CIconRowRemove extends React.Component {
    static propTypes = {
        // 样式
        className: PropTypes.string,
    };

    static defaultProps = {};

    render() {
        return (
            <span
                className={['anticon', 'c-icon-row-remove', this.props.className].join(
                    ' ',
                )}
            >
                <svg
                    viewBox="0 0 1024 1024"
                    version="1.1"
                    focusable="false"
                    width="0.9em"
                    height="0.9em"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M127.744 212.778667a42.410667 42.410667 0 0 1 0-84.778667h768.512a42.410667 42.410667 0 1 1 0 84.778667H127.744z m0 682.666666a42.410667 42.410667 0 1 1 0-84.778666h768.512a42.410667 42.410667 0 0 1 0 84.778666H127.744zM128 469.333333h128a42.666667 42.666667 0 0 1 0 85.333334H128a42.666667 42.666667 0 0 1 0-85.333334z m640 0h128a42.666667 42.666667 0 0 1 0 85.333334h-128a42.666667 42.666667 0 0 1 0-85.333334z m-138.922667-114.346666a47.317333 47.317333 0 0 1 66.432 0 46.506667 46.506667 0 0 1 0 65.664L583.082667 533.333333l112.426666 112.768a46.506667 46.506667 0 0 1 0 65.706667c-9.301333 8.832-20.906667 13.482667-32.981333 13.482667-12.074667 0-23.68-4.181333-32.981333-13.525334l-112.426667-112.725333-112.426667 112.725333c-9.301333 8.874667-20.906667 13.525333-33.024 13.525334-12.074667 0-23.68-4.181333-32.981333-13.525334a46.506667 46.506667 0 0 1 0-65.706666l112.426667-112.725334L338.688 420.693333a46.506667 46.506667 0 0 1 0-65.706666 46.165333 46.165333 0 0 1 65.536 0l112.426667 112.768 112.426666-112.768z"></path>
                </svg>
            </span>
        );
    }
}
