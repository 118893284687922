/**
 * CFormTable
 * @author Tevin、chensi
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button, message, Popconfirm } from 'antd';
import { Tools } from '@components/common/Tools';
import { CIconRowAddNew } from '@components/fragments/icons/CIconRowAddNew';
import { CIconRowRemove } from '@components/fragments/icons/CIconRowRemove';
import { CIconRowInsert } from '@components/fragments/icons/CIconRowInsert';
import { CIconRowCopy } from '@components/fragments/icons/CIconRowCopy';
import { CIconRowClearAll } from '@components/fragments/icons/CIconRowClearAll';
import { CRichTable } from '@components/plugins/richTable/CRichTable';
import { BFormTableContext } from '@components/fragments/formTable/bases/BFormTableContext';
import { CFormTableItem } from '@components/fragments/formTable/CFormTableItem';
import './cFormTable.scss';

export class CFormTable extends React.Component {
    static propTypes = {
        // 表格标题
        title: PropTypes.string,
        // 加载中状态
        loading: PropTypes.bool,
        // 表格列
        defaultColumns: PropTypes.array,
        // 默认数据
        defaultDataResources: PropTypes.array,
        // 主列自动聚焦，输入需要聚焦的键名开启
        //  主列为空时，点其他列的表单元素时聚焦主列
        primaryFocusColumn: PropTypes.string,
        // 变化回调
        onChange: PropTypes.func,
    };

    static defaultProps = {
        title: '可输入表格',
        loading: false,
        defaultColumns: [],
        defaultDataResources: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            // 源数据
            dataResource: [],
            // 选择的行数据
            selectedRowData: {},
            // 选中表格中的行数据下标
            selectedRowIndex: null,
        };
        this.$refs = {
            primaryColumns: [],
        };
    }

    // 获取当前表格数据
    _copyDataResource() {
        return this.state.dataResource.map(rowRes => {
            const { key, ...resetRow } = rowRes;
            return resetRow;
        });
    }

    // 点击设置选中的行的下标和数据值
    _handleRowSelected = (record, index) => {
        this.setState({
            selectedRowIndex: index,
            selectedRowData: record,
        });
    };

    // 添加行
    _handleAddRows() {
        const nextDatas = [...this.state.dataResource, { key: Date.now() }];
        this.setState(
            {
                dataResource: nextDatas,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'addRow',
                    index: this.state.dataResource.length - 1,
                }),
        );
    }

    // 删除行
    _handleDelRows() {
        if (this.state.dataResource.length <= 0) {
            message.warn('请先添加数据！');
            return;
        }
        if (
            this.state.selectedRowIndex === 'null' ||
            Object.keys(this.state.selectedRowData).length <= 0
        ) {
            message.warn('请选择需要删除的行!');
            return;
        }
        // 如果选中最后一行，数据设置成空,位置设置不选中
        if (this.state.selectedRowIndex + 1 === this.state.dataResource.length) {
            this.setState({
                selectedRowData: {},
                selectedRowIndex: null,
            });
        }
        // 选中不是最后一行，数据值加1
        else {
            this.setState({
                selectedRowData: this.state.dataResource[this.state.selectedRowIndex + 1],
            });
        }
        const dataResource = [...this.state.dataResource];
        dataResource.splice(this.state.selectedRowIndex, 1);
        this.setState(
            {
                dataResource,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'removeRow',
                    index: this.state.selectedRowIndex,
                }),
        );
    }

    // 插入行
    _handleInsertRows() {
        if (this.state.dataResource.length <= 0) {
            message.warn('请先添加数据！');
            return;
        }
        if (
            this.state.selectedRowIndex === 'null' ||
            Object.keys(this.state.selectedRowData).length <= 0
        ) {
            message.warn('请选择需要插入的行!');
            return;
        }
        const dataResource = [...this.state.dataResource];
        const nextRowIndex =
            this.state.selectedRowIndex === 0
                ? this.state.selectedRowIndex
                : this.state.selectedRowIndex - 1;
        dataResource.splice(this.state.selectedRowIndex, 0, { key: Date.now() });
        this.setState(
            {
                dataResource,
                selectedRowIndex: this.state.selectedRowIndex,
                selectedRowData: dataResource[this.state.selectedRowIndex],
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'insertRow',
                    index: nextRowIndex,
                }),
        );
    }

    // 复制行
    _handleCopyRows() {
        if (this.state.dataResource.length <= 0) {
            message.warn('请先添加数据！');
            return;
        }
        if (
            this.state.selectedRowIndex === 'null' ||
            Object.keys(this.state.selectedRowData).length <= 0
        ) {
            message.warn('请选择需要复制的行!');
            return;
        }
        const dataResource = [...this.state.dataResource];
        dataResource.splice(this.state.selectedRowIndex + 1, 0, {
            ...dataResource[this.state.selectedRowIndex],
            key: Date.now(),
        });
        this.setState(
            {
                dataResource,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'insertRow',
                    index: this.state.selectedRowIndex + 1,
                }),
        );
    }

    // 清空所有行
    _handleClearAllRows() {
        if (this.state.dataResource.length <= 0) {
            message.warn('请先添加数据！');
            return;
        }
        const dataResource = this.state.dataResource.filter(item => {
            return item?.readOnly === true;
        });
        this.setState(
            {
                selectedRowIndex: null,
                selectedRowData: {},
                dataResource: dataResource,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'clearAll',
                }),
        );
    }

    // 子级表单获取值
    _handleGetItemValue(rowIndex, dataIndex) {
        return this.state.dataResource[rowIndex][dataIndex];
    }

    // 子级表单变更值
    _handleSetItemValue(rowIndex, dataIndex, evt) {
        const nextDataRes = [...this.state.dataResource];
        nextDataRes[rowIndex] = { ...this.state.dataResource[rowIndex] };
        const oldValue = this.state.dataResource[rowIndex][dataIndex];
        if (this._isEvent(evt)) {
            nextDataRes[rowIndex][dataIndex] = evt.target.value;
        } else {
            nextDataRes[rowIndex][dataIndex] = evt;
        }
        this.setState(
            {
                dataResource: nextDataRes,
            },
            () =>
                this.props.onChange(this._copyDataResource(), {
                    type: 'valueChange',
                    rowIndex,
                    dataIndex,
                    oldValue,
                    newValue: nextDataRes[rowIndex][dataIndex],
                }),
        );
    }

    _isEvent(evt) {
        return Boolean(
            evt &&
                typeof evt.type === 'string' &&
                typeof evt.timeStamp === 'number' &&
                typeof evt.target === 'object' &&
                typeof evt.currentTarget === 'object' &&
                (typeof evt.preventDefault === 'function' ||
                    typeof evt.preventDefault === 'object') &&
                (typeof evt.stopPropagation === 'function' ||
                    typeof evt.stopPropagation === 'object'),
        );
    }

    _handleItemFocus(rowIndex, dataIndex) {
        const primaryKey = this.props.primaryFocusColumn;
        if (!primaryKey || dataIndex === primaryKey) {
            return;
        }
        const primaryValue = this.state.dataResource[rowIndex][primaryKey];
        if (!primaryValue) {
            this.$refs.primaryColumns[rowIndex].$childFocus();
        }
    }

    _renderColumns() {
        return (this.props.defaultColumns || []).map(column => {
            if (column.isFormItem) {
                return {
                    ...column,
                    render: (text, record, index) => (
                        <CFormTableItem
                            dataIndex={column.dataIndex}
                            rowIndex={index}
                            ref={elm => {
                                if (
                                    this.props.primaryFocusColumn &&
                                    column.dataIndex === this.props.primaryFocusColumn
                                ) {
                                    this.$refs.primaryColumns[index] = elm;
                                }
                            }}
                        >
                            {column.render(text, record, index)}
                        </CFormTableItem>
                    ),
                };
            } else {
                return column;
            }
        });
    }

    render() {
        return (
            <div className="c-form-table">
                <div className="c-form-table-header">
                    <div className="c-form-table-title">{this.props.title}</div>
                    <div className="c-form-table-btns">
                        <Button size="small" onClick={() => this._handleAddRows()}>
                            <CIconRowAddNew />
                            增行
                        </Button>
                        <Button
                            size="small"
                            onClick={() => this._handleDelRows()}
                            disabled={this.state.selectedRowData.readOnly ? true : false}
                        >
                            <CIconRowRemove />
                            删行
                        </Button>
                        <Button size="small" onClick={() => this._handleInsertRows()}>
                            <CIconRowInsert />
                            插行
                        </Button>
                        <Button
                            size="small"
                            onClick={() => this._handleCopyRows()}
                            disabled={this.state.selectedRowData.readOnly ? true : false}
                        >
                            <CIconRowCopy />
                            复制
                        </Button>
                        <Popconfirm
                            title="是否确认清空所有行？"
                            onConfirm={() => this._handleClearAllRows()}
                            okText="确定"
                            cancelText="再想想"
                        >
                            <Button size="small">
                                <CIconRowClearAll />
                                清空
                            </Button>
                        </Popconfirm>
                    </div>
                </div>
                <BFormTableContext.Provider
                    value={{
                        getItemValue: (...evt) => this._handleGetItemValue(...evt),
                        setItemValue: (...evt) => this._handleSetItemValue(...evt),
                        onChildFocus: (...evt) => this._handleItemFocus(...evt),
                    }}
                >
                    <CRichTable
                        defaultColumns={this._renderColumns()}
                        dataSource={this.state.dataResource}
                        pagination={false}
                        rowClassName={(record, index) =>
                            this.state.selectedRowIndex === index
                                ? 'table-row-selected'
                                : ''
                        }
                        onRow={(record, index) => ({
                            onClick: event => this._handleRowSelected(record, index),
                        })}
                        tableLoading={this.props.loading}
                    />
                </BFormTableContext.Provider>
            </div>
        );
    }

    $getFormData() {
        return this._copyDataResource();
    }

    // 重设所有数据，清除选中
    $resetFormData(resource) {
        if (!resource || !Tools.isArray(resource)) {
            return;
        }
        this.setState({
            selectedRowIndex: null,
            selectedRowData: {},
            dataResource: resource.map((rowData, index) => ({
                ...rowData,
                Key: index,
            })),
        });
    }

    // 单行更新，不清除选中
    $resetFormRow(rowIndex, rowData) {
        if (!rowIndex && rowIndex !== 0) {
            return;
        }
        if (!rowData || !Tools.isObject(rowData)) {
            return;
        }
        const nextDataRes = [...this.state.dataResource];
        nextDataRes[rowIndex] = rowData;
        this.setState({
            dataResource: nextDataRes,
        });
    }
}
